@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

body {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background-color: $primary-bg;
}
// @media only screen and (max-width: 480px){

// }
// @media only screen and (min-width: 480px) and (max-width: 768px){

// }
.navbar-custom {
  background-color: $secondary-bg;
  padding: 1rem 0;
  border-bottom: 1px solid $primary-color;
}

.text-small-btn {
  @media only screen and (max-width: 480px) {
    font-size: 0.7rem;
    padding: 0.5rem !important;
  }
}

.hero-section {
  .banner {
    position: relative;
    & > * {
      z-index: 100;
    }
    height: 80vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      content: ''; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary-text;
      opacity: 0.2;
      z-index: 1;
    }
    @media only screen and (max-width: 480px) {
      height: 30vh;
    }
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      height: 40vh;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      height: 50vh;
    }
  }
  .info-text-banner {
    font-size: 1.5rem;
    @media only screen and (max-width: 480px) {
      font-size: 0.5rem;
    }
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      font-size: 0.8rem;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      font-size: 1.2rem;
    }
  }

  .banner-content {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    @media only screen and (max-width: 480px) {
      left: 50%;
    }
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      left: 40%;
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      left: 40%;
    }
  }

  .banner-text {
    padding: 0 2rem;

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
    }
    @media only screen and (max-width: 480px) {
      padding: 0 0.5rem;
      .info-text-banner {
        font-size: 0.4rem;
      }
      h1 {
        font-size: 1.2rem;
        margin: 0.5rem 0;
      }
    }
    @media only screen and (min-width: 480px) and (max-width: 768px) {
      padding: 0 1rem;

      h1 {
        font-size: 1.5rem;
        margin: 0.5rem 0;
      }
      .info-text-banner {
        font-size: 0.7rem;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
      .info-text-banner {
        font-size: 0.9rem;
      }
      h1 {
        font-size: 2rem;
        margin: 0.5rem 0;
      }
    }
  }
}

.what-is-section {
  min-height: 60vh;
}

#divider {
  margin: 2rem auto;
  height: 2px;
  width: 20%;
  background: linear-gradient(
    90deg,
    $primary-color,
    $temp-color,
    $primary-color
  );
}

h2 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 44px;
  letter-spacing: -0.6px;
}
.getting-started {
  h3 {
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 36px;
    letter-spacing: -0.6px;
    margin-bottom: 2rem;
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }
}
.testimonial {
  p {
    margin-top: 1rem;
    font-weight: 100;
    font-size: 2rem;
    line-height: 36px;
    letter-spacing: -0.6px;
  }
  span {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }
}

.faq {
  .faq-ques {
    cursor: pointer;
    h4 {
      font-weight: normal;
      font-size: 1.1rem;
      line-height: 24px;
      margin-bottom: 2rem;
    }
    color: $primary-color;
    border-bottom: 1px solid $primary-color;
  }
}

.btn-custom {
  background-color: transparent;
  color: $primary-text !important;
  border: 1px solid $primary-color;
  padding: 0.7rem 5rem;

  &:hover {
    background-color: $primary-color;
    color: $primary-bg !important;
  }
}
.btn-custom-simple {
  background-color: $primary-color;
  color: $primary-bg;
  &:hover {
    color: $primary-bg;
  }
}

footer {
  padding: 2rem;
  color: $primary-bg;
  background-color: $primary-color;
  .links-social {
    a {
      color: $primary-bg;
      text-decoration: none;
      &:hover {
        color: $secondary-bg;
      }
      margin: 0 0.2rem;
    }
  }
  .links-ft {
    a {
      color: $primary-bg;
      margin: 0 0.5rem;
      text-decoration: none;
      &:hover {
        color: $secondary-bg;
      }
    }
  }
}

.form-signup {
  input,
  select {
    border: none !important;
    border-bottom: 1px solid $primary-color !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
