$primary-text: #1b1b1b;
$secondary-text: #ffffff;
$primary-color: #661073;
$secondary-color: #f7eff1;
$temp-color: #e5adbd;
$primary-bg: #f9fafb;
$secondary-bg: #ffffff;
$primary-border: #dce0e6;
$primary-header: #661073;

.noselect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
