h1 {
  font-size: 1.5rem;
  font-family: 'Times New Roman', Times, serif;
}
p {
  font-size: 1rem;
}

ul,
li,
h3 {
  font-size: 1.3rem;
}
